<template>
  <div id="theError">
    <div class="l-error l-error_code_404 l-error_region_universal">
      <div class="l-error__container l-error__container_content">
        <div class="l-error__row l-error__row_content">
          <div class="l-error__col l-error__col_content">
            <div class="l-error__row l-error__row_message">
              <div class="l-error__col l-error__col_code_404">
                <div class="l-error__pic-code">404</div>
              </div>
              <div class="l-error__col l-error__col_message">
                <div class="l-error__text">
                  <p>Sorry, the page you are looking for has not been found.</p>
                  <p>
                    Try checking the URL for errors and hit refresh button in
                    your browser.
                  </p>
                </div>
                <div class="l-error__btn-wrap">
                  <a
                    href="/"
                    class="l-error__btn"
                    @click.prevent="$router.go(-1)"
                  >
                    Go back
                  </a>
                </div>
              </div>
            </div>
            <div class="l-error__row l-error__row_nav">
              <div class="l-error__col l-error__col_nav l-error__col_nav-4">
                <div
                  class="
                    b-error-nav
                    l-error__block l-error__block l-error__block_type_nav
                  "
                >
                  <div class="b-error-nav__title">News</div>
                  <ul class="b-error-nav__list">
                    <li class="b-error-nav__item">
                      <a
                        href="https://cointelegraph.com/tags/bitcoin"
                        target="_self"
                        class="b-error-nav__link"
                        >Bitcoin News</a
                      >
                    </li>
                    <li class="b-error-nav__item">
                      <a
                        href="https://cointelegraph.com/tags/ethereum"
                        target="_self"
                        class="b-error-nav__link"
                        >Ethereum News</a
                      >
                    </li>
                    <li class="b-error-nav__item">
                      <a
                        href="https://cointelegraph.com/tags/altcoin"
                        target="_self"
                        class="b-error-nav__link"
                        >Altcoin News</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="l-error__col l-error__col_nav l-error__col_nav-4">
                <div
                  class="
                    b-error-nav
                    l-error__block l-error__block l-error__block_type_nav
                  "
                >
                  <div class="b-error-nav__title">Features</div>
                  <ul class="b-error-nav__list">
                    <li class="b-error-nav__item">
                      <a
                        href="https://cointelegraph.com/category/hodlers-digest"
                        target="_self"
                        class="b-error-nav__link"
                        >Hodler’s Digest</a
                      >
                    </li>
                    <li class="b-error-nav__item">
                      <a
                        href="https://cointelegraph.com/category/follow-up"
                        target="_self"
                        class="b-error-nav__link"
                        >Follow-Up</a
                      >
                    </li>
                    <li class="b-error-nav__item">
                      <a
                        href="https://cointelegraph.com/category/analysis"
                        target="_self"
                        class="b-error-nav__link"
                        >Analysis</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="l-error__col l-error__col_nav l-error__col_nav-4">
                <div
                  class="
                    b-error-nav
                    l-error__block l-error__block l-error__block_type_nav
                  "
                >
                  <div class="b-error-nav__title">Price Analysis</div>
                  <ul class="b-error-nav__list">
                    <li class="b-error-nav__item">
                      <a
                        href="https://cointelegraph.com/category/top-10-cryptocurrencies"
                        target="_self"
                        class="b-error-nav__link"
                        >Top 10 Cryptocurrencies</a
                      >
                    </li>
                    <li class="b-error-nav__item">
                      <a
                        href="https://cointelegraph.com/category/market-analysis"
                        target="_self"
                        class="b-error-nav__link"
                        >Market Analysis</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="l-error__col l-error__col_nav l-error__col_nav-4">
                <div
                  class="
                    b-error-nav
                    l-error__block l-error__block l-error__block_type_nav
                  "
                >
                  <div class="b-error-nav__title">Market Tools</div>
                  <ul class="b-error-nav__list">
                    <li class="b-error-nav__item">
                      <a
                        href="https://cointelegraph.com/price-indexes"
                        target="_self"
                        class="b-error-nav__link"
                        >Price Indexes</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  name: "404",
  setup () {
      useMeta({ title: 'The page you are looking for has not been found' })
  }
};
</script>

<style lang="scss">
.l-error {
  margin-bottom: 70px;
  @media (min-width: 768px) {
    margin-bottom: 135px;
  }
  @media (min-width: 992px) {
    margin-bottom: 160px;
  }
  &__container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 30px;
    @media (min-width: 768px) {
      margin-top: 135px;
      width: 740px;
    }
    @media (min-width: 992px) {
      margin-top: 160px;
      width: 960px;
    }
    @media (min-width: 1200px) {
      margin-top: 24vh;
      width: 1160px;
    }
  }
  &__row {
    margin-left: -10px;
    margin-right: -10px;
    &:after,
    &:before {
      content: " ";
      display: table;
    }
    &:after {
      clear: both;
    }
    &:not(:first-child) {
      margin-top: 30px;
      @media (min-width: 768px) {
        margin-top: 40px;
      }
      @media (min-width: 992px) {
        margin-top: 80px;
      }
    }
    &_message {
      display: block;
      text-align: center;
      @media (min-width: 768px) {
        align-items: center;
        display: flex;
        text-align: left;
      }
    }
  }
  &__col {
    &_content {
      float: left;
      width: 100%;
      position: relative;
      min-height: 1px;
      padding-left: 10px;
      padding-right: 10px;
      @media (min-width: 992px) {
        float: left;
        width: 83.33333333%;
        margin-left: 8.33333333%;
      }
      @media (min-width: 1200px) {
        float: left;
        width: 66.66666667%;
        margin-left: 16.66666667%;
      }
    }
    &_code_404 {
      position: relative;
      min-height: 1px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
      margin-bottom: 40px;
      display: block;
      @media (min-width: 768px) {
        float: left;
        width: 66.66666667%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        padding-left: 20px;
        padding-right: 20px;
      }
      @media (min-width: 992px) {
        margin-bottom: 0;
        align-items: center;
      }
    }
    &_message {
      padding-left: 10px;
      padding-right: 10px;
      @media (min-width: 768px) {
        margin-left: 9%;
        position: relative;
        min-height: 1px;
        padding-left: 10px;
        padding-right: 10px;
        float: left;
        width: 41.66666667%;
      }
      @media (min-width: 992px) {
        margin-left: 12.5%;
      }
    }

    &_nav {
      float: left;
      width: 66.66666667%;
      margin-left: 16.66666667%;
      position: relative;
      min-height: 1px;
      padding-left: 10px;
      padding-right: 10px;
      @media (min-width: 768px) {
        float: left;
        width: 33.33333333%;
        margin-left: 0;
      }
      @media (min-width: 992px) {
        float: left;
        width: 25%;
      }
    }

    &_nav-3 {
      position: relative;
      min-height: 1px;
      padding-left: 10px;
      padding-right: 10px;
      @media (min-width: 768px) {
        float: left;
        width: 33.33333333%;
      }
    }
  }
  &__pic-code {
    font-family: inherit;
    font-weight: 800;
    color: #eceff1;
    line-height: 0.77;
    text-shadow: -1px -1px 0 #465a65, 1px -1px 0 #465a65, -1px 1px 0 #465a65,
      1px 1px 0 #465a65;
    text-align: center;
    display: inline-block;
    font-size: 116px;
    @media (min-width: 768px) {
      font-size: 128px;
    }
    @media (min-width: 992px) {
      font-size: 208px;
    }
  }
  &__text {
    color: #465a65;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.4;
    font-size: 16px;
    display: inline-block;
    text-align: left;

    p {
      margin: 0;
      &:not(:first-child) {
        margin-top: 24px;
      }
    }
  }
  &__btn-wrap {
    margin-top: 25px;
  }
  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 55px;
    height: 40px;
    border-radius: 20px;
    background-color: #f7b323;
    color: #253137;
    font-family: inherit;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    transition: background-color 0.3s;
    @media (min-width: 992px) {
      padding: 12px 60px;
    }
    &:hover {
      background-color: #dda01f;
    }
    &:active,
    &:link,
    &:visited,
    &:focus {
      text-decoration: none;
      color: #253137;
    }
  }

  &_region_arabian * {
    direction: rtl;
  }
}
.l-error__article-error {
  position: relative;
  font-size: 16px;
  color: #000000;
}
.l-error__article-error::before {
  content: "";
  position: absolute;
  left: calc(50% - 300px / 2);
  top: 0;
  width: 300px;
  height: 300px;
  background-color: #ebeff1;
  border-radius: 50%;
  pointer-events: none;
  @media (min-width: 768px) {
    left: 0;
    width: 367px;
    height: 367px;
  }
}
.l-error__article-error-wrp {
  position: relative;
  z-index: 1;
  min-height: 300px;
  padding-top: 10px;
  text-align: start;
  @media (min-width: 768px) {
    min-height: 367px;
    padding-top: 50px;
  }
}
.l-error__article-error-prefix {
  margin: 0;
}
.l-error__article-error-title {
  margin-top: 5px;
  font-size: 22px;
  font-weight: 700;
  @media (min-width: 768px) {
    font-size: 26px;
    line-height: 40px;
  }
}
.l-error__article-error-postfix {
  margin: 10px 0 0;
}
.l-error__article-error-mascotte {
  display: block;
  width: 89px;
  height: 89px;
  margin: 40px 0 0 64px;
  background: url("/assets/img/not-found/c-man-trash@2x.png") no-repeat center;
  background-size: contain;
}

.b-error-nav {
  &__title {
    color: #475a65;
    font-family: inherit;
    font-size: 16px;
    font-weight: 800;
    border-radius: 2px;
    background-color: #eceff1;
    padding: 9px 16px;
  }
  &__list {
    list-style-type: none;
    padding: 16px 16px 24px;
    margin: 0;
    @media (min-width: 768px) {
      padding-bottom: 16px;
    }
  }
  &__item {
    &:not(:first-child) {
      margin-top: 8px;
    }
    @media (min-width: 768px) {
      &:last-of-type {
        margin-bottom: 34px;
      }
    }
    @media (min-width: 992px) {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &__link {
    &:link,
    &:visited,
    &:focus,
    &:active {
      color: #475a65;
      font-family: inherit;
      font-size: 12px;
      font-weight: 400;
      text-decoration: none;
      line-height: 1.5;
    }
    &:hover {
      color: #475a65;
      text-decoration: none;
    }
  }
}
</style>
